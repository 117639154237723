import React, { FC, useCallback } from 'react';

import GatsbyImage from 'components/common/GatsbyImage';

import { BuyNowItemTypes } from './models';

import './BuyNowItem.scss';

const BuyNowItem: FC<BuyNowItemTypes> = ({ properties, retailerClickHandler }) => {
  const handleRetailerClick = useCallback(
    (shopName: string) => () => {
      if (typeof retailerClickHandler !== 'function') return;
      retailerClickHandler(shopName);
    },
    []
  );
  return (
    <li key={properties.linkToShop} className="buy-now-item">
      <a
        className="buy-now-item__link"
        href={properties.linkToShop}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleRetailerClick(properties?.name)}
      >
        <GatsbyImage
          className="buy-now-item__image"
          fluid={properties.image}
          alt={properties.ariaLabel}
        />
      </a>
    </li>
  );
};

export default BuyNowItem;
