import React, { FC } from 'react';
import classnames from 'classnames';

import RecommendedProducts from 'components/RecommendedProducts';
import VideoPlayer from 'components/VideoBlock/VideoPlayer';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Faq from 'components/Faq';

import { SymptomBlockProps } from './model';
import './SymptomBlock.scss';

const SymptomBlock: FC<SymptomBlockProps> = ({
  text,
  color,
  title,
  items,
  link,
  isFamily,
  list,
  products,
  videoSettings,
}) => {
  const cx = classnames({
    'gs-symptom-section__panel-block': true,
    [`bg-color-${color}`]: color,
  });

  return (
    <>
      {link && videoSettings ? (
        <div className="gs-symptom-section__panel-block">
          <VideoPlayer
            onlyVideo
            link={link}
            preTitle={videoSettings.preTitle}
            timeNames={{ sec: videoSettings.secText, min: videoSettings.minText }}
          />
        </div>
      ) : null}
      {title ? (
        items?.length ? (
          <Faq {...{ title, items }} />
        ) : products?.nodes.length ? (
          <RecommendedProducts
            title={title}
            products={products}
            bgColor={color}
            productLinks={list}
            isFamily={isFamily}
          />
        ) : null
      ) : (
        <DangerouslySetInnerHtml className={cx} html={text} />
      )}
    </>
  );
};

export default SymptomBlock;
