import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import './ProductVariats.scss';

const ProductVariats: FC<ProductPageTypes.IProductVariants> = ({ variants, title }) => (
  <div className="product-variants" data-testid="product-variants">
    <strong className="product-variants__title">{title}</strong>
    <ul className="product-variants__list">
      {variants.map(({ properties: { icon: [name], label } }) => (
        <li key={label} className="product-variants__list-item">
          <IconCustom icon={name} />
          <DangerouslySetInnerHtml className="product-variants__list-label" html={label} />
        </li>
      ))}
    </ul>
  </div>
);

export default ProductVariats;
