import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import BuyNowItem from './BuyNowItem';
import { ProductBuyNowDropdownTypes } from './models';

import './ProductBuyNowDropdown.scss';

const ProductBuyNowDropdown: FC<ProductBuyNowDropdownTypes> = ({
  buyNowData,
  retailerClickHandler,
}) => {
  const {
    toggleButtonTitle,
    toggleButtonAriaLabel,
    description,
    cancelButtonTitle,
    cancelButtonAriaLabel,
    dropdownItems,
  } = buyNowData;

  if (!dropdownItems?.length || !toggleButtonTitle || !cancelButtonTitle) return null;

  return (
    <Dropdown className="product-buy-now-dropdown">
      <Dropdown.Toggle className="gs-btn gs-btn--buy-dropdown" aria-label={toggleButtonAriaLabel}>
        {toggleButtonTitle}
      </Dropdown.Toggle>

      <Dropdown.Menu className="product-buy-now-dropdown__popup">
        <div className="product-buy-now-dropdown__buy-now">
          <DangerouslySetInnerHtml html={description} element="p" />

          <ul className="product-buy-now-dropdown__items">
            {dropdownItems.map((item) => (
              <BuyNowItem
                key={item?.properties?.linkToShop}
                {...item}
                retailerClickHandler={retailerClickHandler}
              />
            ))}
          </ul>

          <Dropdown.Item className="product-buy-now-dropdown__close-btn">
            <Button className="gs-btn gs-btn--cancel-dropdown" aria-label={cancelButtonAriaLabel}>
              {cancelButtonTitle}
            </Button>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProductBuyNowDropdown;
